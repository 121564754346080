html,
body {
    margin: 0;
    padding: 0;
    font-family: Roboto,sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: Quicksand, sans-serif;
}

// responsive h1 font size
h1 {
    font-size: calc(1.375rem + 1.5vw);
}

.page:not([active]) {
    display: none;
}

.page[active] {
    display: block;
}
.highlight {
	background-color: #F6FFD2;
}

.landing {
    &__subscribe {
        width: 50%;
        max-width: 500px;
        border-radius: 5%;
        padding: 20px;
        font-size: 26px;
        color: #4c527d;
        text-align: center;
        position: relative;

        @media only screen and (max-width: 991) {
            width: auto;
        }
    }
}